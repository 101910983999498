

































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ADD_API, EDIT_API, GET_APIS, GET_PLATFORM_CONFIG,
} from '@/store/types';
import { API } from '@/store/apis/models';
import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { API_PATH_REGEX } from '@/scripts/shared';
import { namespaces } from '@/scripts/namespaces';

@Component
export default class AddAPI extends Vue {
  @Prop() api!: any;

  // Getters
  @Getter(GET_APIS, { namespace: namespaces.API }) apis: any;

  @Getter(GET_PLATFORM_CONFIG, { namespace: namespaces.PLATFORM_CONFIG }) platformConfig: any;

  // Actions
  @Action(ADD_API, { namespace: namespaces.API }) addApi: any;

  @Action(EDIT_API, { namespace: namespaces.API }) editApi: any;

  // Data
  private newPath: string = '';

  private name: string = '';

  private apiKey: string = '';

  private workspaceId: string = this.$route.params.workspaceId;

  // Computed
  get pathPrefix(): string {
    return this.platformConfig.apiBasePath;
  }

  @Validations()
  validations = {
    newPath: {
      required,
      unique: (value: string) => {
        if (value) {
          return !this.isPathUnique(value);
        }
        return true;
      },
    },
    name: {
      required,
      unique: (value: any) => {
        if (value) {
          return !this.isNameUnique(value);
        }
        return true;
      },
    },
  };

  @Watch('api')
  onApiChange(value: any) {
    if (value) {
      this.initEditMode();
    } else {
      this.reset();
    }
  }

  initEditMode() {
    if (this.api) {
      this.name = this.api.name;
      this.newPath = this.api.path;
      this.apiKey = this.api.apiKey;
    }
  }

  get newPathError() {
    const errors: any[] = [];

    if (!this.$v.newPath.$dirty) return errors;

    if (!this.$v.newPath.required) errors.push(this.$t('required_field'));

    if (!this.$v.newPath.unique) errors.push(this.$t('unique_path'));

    if (!API_PATH_REGEX.test(this.newPath.trim().toLowerCase())) {
      errors.push(this.$t('invalid_url'));
    }

    return errors;
  }

  get apiNameError() {
    const errors: any[] = [];
    if (!this.$v.name.$dirty) return errors;

    if (!this.$v.name.required) errors.push(this.$t('required_field'));

    if (!this.$v.name.unique) errors.push(this.$t('unique_api_name'));

    return errors;
  }

  // Methods
  close() {
    this.$emit('close');
    this.reset();
  }

  add() {
    this.$v.$touch();

    if (this.$v.$invalid) return;

    const newApi: API = {
      name: this.name.trim(),
      path: this.newPath.trim().toLowerCase(),
      apiKey: this.apiKey,
      workspaceId: this.workspaceId,
      endpoints: [],
    };

    this.addApi({ workspaceId: this.workspaceId, api: newApi });
    this.close();
  }

  edit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const updateApi: API = {
      id: this.api.id,
      name: this.name.trim(),
      path: this.newPath.trim().toLowerCase(),
      apiKey: this.apiKey,
      workspaceId: this.api.workspaceId,
      endpoints: this.api.endpoints,
    };

    this.editApi({ workspaceId: this.workspaceId, api: updateApi });
    this.close();
  }

  reset() {
    this.name = '';
    this.newPath = '';
    this.apiKey = '';

    this.$v.$reset();
  }

  isNameUnique(name: any) {
    if (this.apis) {
      const isExist = this.apis.find((a: API) => {
        if (this.api && this.api.name.toLowerCase() === a.name.toLowerCase()) {
          return false;
        }
        return a.name.toLowerCase() === name.trim().toLowerCase();
      });
      return !!isExist;
    }
    return false;
  }

  isPathUnique(path: string) {
    if (this.apis) {
      return !!this.apis.find((a: API) => {
        if (this.api && this.api.path.toLowerCase() === a.path.toLowerCase()) {
          return false;
        }
        return a.path.toLowerCase() === path.trim().toLowerCase();
      });
    }
    return false;
  }

  // Vue Life Cycle Hooks
  mounted() {
    if (this.api) {
      this.initEditMode();
    }
  }
}
