
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DELETE_ENDPOINT } from '@/store/types';
import { API, Endpoint } from '@/store/apis/models';
import { namespaces } from '@/scripts/namespaces';

@Component
export default class DeleteEndpoint extends Vue {
  // Props
  @Prop() api!: API;

  @Prop() endpoint!: Endpoint;

  // Actions
  @Action(DELETE_ENDPOINT, { namespace: namespaces.API }) deleteEndpoint: any;

  // Data
  private dialog = false;

  private deleteLoading = false;

  private workspaceId: string = this.$route.params.workspaceId;

  // Methods
  /**
   * Removes the Endpoint from the API
   */
  async removeEndpoint() {
    this.deleteLoading = true;

    try {
      await this.deleteEndpoint({
        workspaceId: this.workspaceId,
        apiId: this.api.id,
        endpointId: this.endpoint.id,
      });

      this.deleteLoading = false;
      this.dialog = false;
    } catch (error) {
      this.deleteLoading = false;
    }
  }
}
