var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.api.name)+" "),_c('span',{staticClass:"title"},[_vm._v("(/"+_vm._s(_vm.api.path)+")")])])]),_c('v-slide-x-transition',[(!open && _vm.api.apiKey !== '')?_c('v-col',{staticClass:"mt-2 mr-4",attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" API-Key: ")]),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.api.apiKey))])]):_vm._e()],1),_c('v-col',{staticClass:"d-flex mr-3",attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"width":"550"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",staticStyle:{"margin-top":"2px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.editDialog)?_c('AddAPI',{attrs:{"api":_vm.api},on:{"close":function($event){_vm.editDialog = false}}}):_vm._e()],1),_c('DeleteAPI',{attrs:{"api":_vm.api}})],1)],1)]}}])}),_c('v-expansion-panel-content',{staticClass:"mt-2"},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{staticClass:"mt-2 mb-2",attrs:{"lg":"5","cols":"12"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" API-Key: ")]),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.api.apiKey))])]),_c('v-col',{attrs:{"lg":"5","cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":_vm.$vuetify.breakpoint.lgAndUp ? 'end' : ''}},[_c('v-col',{class:_vm.$vuetify.breakpoint.lgOnly ? 'mb-2' : '',attrs:{"xl":"5","lg":"10","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('search_endpoint'),"hide-details":"","outlined":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mx-4",attrs:{"cols":"auto"}},[_c('AddOrEditEndpoint',{attrs:{"api":_vm.api,"mode":_vm.modes.ADD}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.api.endpoints,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.request.method",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-uppercase",attrs:{"label":"","color":item.request.method.toLowerCase() === 'post' ? 'green' : 'blue',"dark":""}},[_vm._v(" "+_vm._s(item.request.method)+" ")])]}},{key:"item.request.path",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.request.path)+" ")])]}},{key:"item.request.payload",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic d-inline-block text-truncate",style:(("max-width: " + (_vm.getMaxWidth()) + ";"))},[_vm._v(" "+_vm._s(_vm.beautifyPayload(item.request.payload))+" ")]),(!_vm.isString(item.request.payload))?_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.expandJSON(item.request.payload)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-horizontal")])],1):_vm._e()]}},{key:"item.response.payload",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic d-inline-block text-truncate",style:(("max-width: " + (_vm.getMaxWidth()) + ";"))},[_vm._v(" "+_vm._s(_vm.beautifyPayload(item.response.payload))+" ")]),(!_vm.isString(item.response.payload))?_c('v-btn',{staticClass:"mb-2",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.expandJSON(item.response.payload)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-horizontal")])],1):_vm._e()]}},{key:"item.flows",fn:function(ref){
var item = ref.item;
return _vm._l((item.flows),function(flow,i){return _c('a',{key:i,on:{"click":function($event){return _vm.redirectToFlow(flow)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle-small")]),_vm._v(" "+_vm._s(_vm.$t('go_to_flow'))+" ")],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('AddOrEditEndpoint',{attrs:{"api":_vm.api,"mode":_vm.modes.EDIT,"endpoint":item}}),_c('DeleteEndpoint',{attrs:{"api":_vm.api,"endpoint":item}})],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500","transition":"scroll-y-reverse-transition"},model:{value:(_vm.showPayload),callback:function ($$v) {_vm.showPayload=$$v},expression:"showPayload"}},[(_vm.showPayload)?_c('v-card',[_c('v-card-title',[_vm._v(" Payload "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showPayload = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_c('pre',{domProps:{"innerHTML":_vm._s(_vm.beautifyPayload(_vm.selectedItem))}})])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }