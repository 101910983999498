
































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

@Component
export default class Workspaces extends Vue {
  @Prop({ default: '' }) message: any

  // Getters

  // Vue Life Cycle Hooks

  refresh = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
}
